import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  ErrorMessage,
  Loading,
  Card,
  Spacing,
  CardBody,
  Typography,
  Button,
  FlexBox,
} from "@web-applications/daffodil-component-library";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";

export const GET_BUSINESS_DETAILS = gql`
  query getBusinessDetails($businessId: ID!) {
    getBusiness(businessId: $businessId) {
      id
      name
    }
    getSites(businessId: $businessId) {
      id
      logo
      name
      reference
    }
  }
`;

export default function BusinessDetails({
  match: {
    params: { businessId },
  },
}) {
  const { loading, error, data } = useQuery(GET_BUSINESS_DETAILS, {
    variables: { businessId },
  });

  if (loading) return <Loading />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <Container>
      <Row>
        <Col>
          <Spacing multiplier={4} />
          <FlexBox justifyContent="space-between">
            <Typography variant="h1">{data.getBusiness.name}</Typography>
            <Button
              component={Link}
              to="./site/add"
              icon={<Add style={{ marginRight: "10px" }} />}
            >
              New Site
            </Button>
          </FlexBox>
          <Spacing multiplier={5} />
        </Col>
      </Row>
      <Row>
        {data.getSites.map(({ id, logo, name, reference }) => (
          <Col md={4} key={id}>
            <Link to={`/site/${reference}`}>
              <Card>
                <CardBody>
                  <Spacing multiplier={4} />
                  <img src={logo} alt="Site Logo" height="80px" width="100%" />
                  <Spacing multiplier={4} />
                  <Typography variant="h5" center>
                    {name}
                  </Typography>
                </CardBody>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
